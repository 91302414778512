import { ChangeDetectorRef, OnDestroy, Renderer2, ViewChild } from '@angular/core';
import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators, UntypedFormArray } from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import { CustomValidatorsService } from '@app/core/services/custom-validators.service';
import {
  Application,
  ApplicationsService,
  CatalogueEntry,
  CataloguesService,
  Connector,
  ConnectorsService,
  ListAllCatalogueEntriesRequestParams,
  ListRuntimeStatusRequestParams,
  Organisation,
  RuntimeStatus,
  Environment,
  ConnectorSpec,
  OrganisationsService,
} from '@agilicus/angular';
import { KeyTabManager } from '../key-tab-manager/key-tab-manager';
import {
  capitalizeFirstLetter,
  getEmptyStringIfUnset,
  getStatusIcon,
  getStatusIconColor,
  getValuesArray,
  modifyDataOnFormBlur,
} from '../utils';
import { UserAuthOption } from '@app/shared/components/user-auth-option.enum';
import { RuntimeOption } from '@app/shared/components/runtime-option.enum';
import { AuthMethodOption } from '@app/shared/components/auth-method-option.enum';
import { combineLatest, EMPTY, Observable, of, Subject } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { AppState, NotificationService } from '@app/core';
import {
  ActionApiApplicationsInitApplications,
  ActionApiApplicationsResetApplicationModel,
  ActionApiApplicationsSubmittingApplicationModel,
  ActionApiApplicationsUpdateApplicationModel,
} from '@app/core/api-applications/api-applications.actions';
import { selectApiApplications } from '@app/core/api-applications/api-applications.selectors';

import { selectCanAdminApps } from '@app/core/user/permissions/app.selectors';
import { OrgQualifiedPermission } from '@app/core/user/permissions/permissions.selectors';
import { catchError, concatMap, delay, expand, map, mergeMap, reduce, takeUntil, takeWhile } from 'rxjs/operators';
import { cloneDeep } from 'lodash-es';
import {
  AccessOptionData,
  ApplicationModel,
  FqdnAliasOptionData,
  VersionedApplicationModel,
} from '@app/core/models/application/application-model';
import { downloadJSON, getFile, getJSONFromFileReader, uploadIsJSON } from '../file-utils';
import { ProgressBarController } from '../progress-bar/progress-bar-controller';
import { AccessOption } from '../access-option.enum';
import { AuthFlowOption } from '../auth-flow-option.enum';
import { StartOption } from '../start-option.enum';
import { DefaultStepperState } from '../default-stepper-state';
import {
  canConfigureRolesAndRules,
  canConfigureRuntime,
  delayStepperAdvanceOnSuccessfulApply,
  disableProxyAuthFlowOption,
  getAccessOptionData,
  getAccessOptionDataFromAccessOption,
  getAccessOptionValue,
  getAuthenticationMethodOptionValue,
  getAuthenticationOptionValue,
  getAuthFlowOptionData,
  getAuthFlowOptionDataFromAuthFlowOption,
  getAuthMethodOptionDataFromAuthMethodOption,
  getAuthorizationOptionValue,
  getFqdnAliasOptionData,
  getFqdnAliasOptionValue,
  getRuntimeOptionValue,
  getUnversionedApplicationModel,
  getUserAuthOptionDataFromUserAuthOption,
  getVersionedApplicationModel,
  handleStateOnFirstStepSelection,
} from '../application-template-utils';
import { ApplicationStepperController } from './application-stepper-controller';
import {
  getApplicationUrl,
  getDefaultEnvironmentName,
  getImageValueFromModel,
  getNoAppIdForStatusString,
  getPrimaryExternalName,
} from '@app/core/models/application/application-model-api-utils';
import {
  isAccessedOnPrem,
  isAccessedViaAgent,
  isAccessedViaVpn,
  isAuthenticatedViaOidc,
  isAuthenticatedViaProxy,
  isAuthenticatedViaSaml,
  isRuntimeEnabled,
  isUsingAgilicusRuntime,
  isUsingOwnRuntime,
} from '@app/core/models/application/application-model-utils';
import { ApiApplicationsState } from '@app/core/api-applications/api-applications.models';
import { selectCanAdminUsers } from '@app/core/user/permissions/users.selectors';
import { selectCanAdminIssuers } from '@app/core/user/permissions/issuers.selectors';
import { selectCurrentOrganisation } from '@app/core/organisations/organisations.selectors';
import { FqdnAliasOption } from '../fqdn-alias-option.enum';
import { ENTER, COMMA } from '@angular/cdk/keycodes';
import { MatLegacyChipInputEvent as MatChipInputEvent } from '@angular/material/legacy-chips';
import { IconColor } from '../icon-color.enum';
import { getIgnoreErrorsHeader } from '../../../core/http-interceptors/http-interceptor-utils';
import { FilterChipOptions } from '../filter-chip-options';
import { getConnectors } from '@app/core/api/connectors/connectors-api-utils';
import { validateFqdnAliasValues } from '../validation-utils';
import { StepperType } from '../stepper-type.enum';
import { getDefaultApplicationModel } from '../../../core/models/application/application-model-utils';
import { TlsType } from '../tls-type.enum';
import { getRedirectSubpathTooltipText } from '@app/core/api-applications/api-applications-utils';
import { getOrgFeatureFlagMap$, OrgFeatures } from '@app/core/api/organisations/organisations-api.utils';
import { ResourceType } from '../resource-type.enum';

export interface ApplicationStepperState extends DefaultStepperState {
  selectedFqdnAlias: FqdnAliasOption;
  selectedAccess: AccessOption;
  selectedAuthFlow: AuthFlowOption;
  selectedAuthMethod: AuthMethodOption;
  selectedUserAuth: UserAuthOption;
  selectedRuntime: RuntimeOption;
}

export interface CombinedPermissionsConnectorsAndFeatures {
  permission: OrgQualifiedPermission;
  connectors: Array<Connector>;
  features: Map<string, boolean>;
}

@Component({
  selector: 'portal-application-stepper',
  templateUrl: './application-stepper.component.html',
  styleUrls: ['./application-stepper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ApplicationStepperComponent implements OnInit, OnDestroy {
  private unsubscribe$: Subject<void> = new Subject<void>();
  private appState$: Observable<ApiApplicationsState>;
  private hasAppsPermissions$: Observable<OrgQualifiedPermission>;
  private hasUsersPermissions$: Observable<OrgQualifiedPermission>;
  private hasIssuersPermissions$: Observable<OrgQualifiedPermission>;
  public hasAppsPermissions: boolean;
  public hasUsersPermissions: boolean;
  public hasIssuersPermissions: boolean;
  public currentOrg: Organisation;
  private templateCatalogueEntries$: Observable<Array<CatalogueEntry>>;
  public templateCatalogueEntries: Array<CatalogueEntry> = [];
  public appState: ApiApplicationsState;
  public currentApplicationModel: ApplicationModel;
  private applications: Array<Application>;
  public appStepperState: ApplicationStepperState = {
    selectedFqdnAlias: undefined,
    selectedStartOption: undefined,
    selectedTemplates: [],
    selectedAccess: undefined,
    selectedAuthFlow: undefined,
    selectedAuthMethod: undefined,
    selectedUserAuth: undefined,
    selectedRuntime: undefined,
  };
  public templateSelectFormGroup: UntypedFormGroup;
  public appDetailsFormGroup: UntypedFormGroup;
  public accessFormGroup: UntypedFormGroup;
  public authFlowFormGroup: UntypedFormGroup;
  public upstreamServiceFormGroup: UntypedFormGroup;
  public runtimeFormGroup: UntypedFormGroup;
  public connectorFormGroup: UntypedFormGroup;
  public startOptionFormGroup: UntypedFormGroup;
  public fqdnAliasOptionFormGroup: UntypedFormGroup;
  public rewriteMediaTypesFormGroup: UntypedFormGroup;
  public http2FormGroup: UntypedFormGroup;
  public webApplicationFirewallFormGroup: UntypedFormGroup;
  public corsTypeFormGroup: UntypedFormGroup;
  public commonPathPrefixFormGroup: UntypedFormGroup;
  public completedApplicationText = `Your application setup is complete. Please review it below. Make any corrections needed above. You may download this setup for later review, or, for use in a template for the next application.
  After you apply, you may make any later changes on the Define tab at the left.`;
  public stepperType = StepperType.application;
  public fqdnAliasOptionData: Array<FqdnAliasOptionData>;
  public hasTemplates = true;
  public accessOptionData = getAccessOptionData();
  public authFlowOptionData = getAuthFlowOptionData();
  private appNameToAppIdMap: Map<string, string> = new Map();
  private currentAppModelVersion = 'v1.0';
  public connectors: Array<Connector>;
  public appStatus$: Observable<Array<number | RuntimeStatus> | Array<number>>;
  public appStatus: RuntimeStatus;
  public resourceType = ResourceType.application;
  public orgId: string;
  public showResourcePermissions = false;

  public pageDescriptiveText = `Create a new web application in a guided fashion`;
  public productGuideLink = `https://www.agilicus.com/anyx-guide/product-guide-applications/#h-new`;
  public redirectSubpathTooltipText = getRedirectSubpathTooltipText();
  public authenticationStepProductGuideLink = `https://www.agilicus.com/anyx-guide/product-guide-applications/#application-authentication`;
  private orgFeatureKeyToEnabledMap: Map<string, boolean> = new Map();

  // Workaround for angular stepper bug:
  // See: https://github.com/angular/components/issues/20923
  public accessOptionChanged = false;
  public authFlowOptionChanged = false;

  // This is required in order to reference the enums in the html template.
  public accessOption = AccessOption;
  public userAuthOption = UserAuthOption;
  public runtimeOption = RuntimeOption;
  public authMethodOption = AuthMethodOption;
  public authFlowOption = AuthFlowOption;
  public fqdnAliasOption = FqdnAliasOption;
  public iconColor = IconColor;

  public isUploadingTemplate = false;
  public isSubmittingAppModel = false;

  // For setting enter key to change input focus.
  public keyTabManager: KeyTabManager = new KeyTabManager();

  public templateProgressBarController: ProgressBarController = new ProgressBarController();
  public appStepperController: ApplicationStepperController = new ApplicationStepperController();
  public appModelSubmissionProgressBarController: ProgressBarController = new ProgressBarController();

  public capitalizeFirstLetter = capitalizeFirstLetter;
  public getAccessOptionDataFromAccessOption = getAccessOptionDataFromAccessOption;
  public getAuthFlowOptionDataFromAuthFlowOption = getAuthFlowOptionDataFromAuthFlowOption;
  public isAccessedViaAgent = isAccessedViaAgent;
  public getAuthMethodOptionDataFromAuthMethodOption = getAuthMethodOptionDataFromAuthMethodOption;
  public getUserAuthOptionDataFromUserAuthOption = getUserAuthOptionDataFromUserAuthOption;
  public canConfigureRuntime = canConfigureRuntime;
  public isAuthenticatedViaSaml = isAuthenticatedViaSaml;
  public isAuthenticatedViaOidc = isAuthenticatedViaOidc;
  public isAuthenticatedViaProxy = isAuthenticatedViaProxy;
  public isRuntimeEnabled = isRuntimeEnabled;
  public getImageValueFromModel = getImageValueFromModel;
  public isUsingOwnRuntime = isUsingOwnRuntime;
  public isUsingAgilicusRuntime = isUsingAgilicusRuntime;
  public isAccessedOnPrem = isAccessedOnPrem;
  public getPrimaryExternalName = getPrimaryExternalName;
  public getStatusIcon = getStatusIcon;
  public getStatusIconColor = getStatusIconColor;
  public canConfigureRolesAndRules = canConfigureRolesAndRules;

  public filterChipOptions: FilterChipOptions = {
    visible: true,
    selectable: true,
    removable: true,
    addOnBlur: true,
    separatorKeysCodes: [ENTER, COMMA],
  };

  @ViewChild('stepper') public stepper: MatStepper;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private customValidatorsService: CustomValidatorsService,
    private changeDetector: ChangeDetectorRef,
    private store: Store<AppState>,
    private cataloguesService: CataloguesService,
    private renderer: Renderer2,
    private notificationService: NotificationService,
    private connectorsService: ConnectorsService,
    private applicationsService: ApplicationsService,
    private organisationsService: OrganisationsService
  ) {}

  public ngOnInit(): void {
    this.resetModel();
    // Forcefully refresh the set of applications, and set the current application to
    // a 'blank' one.
    this.store.dispatch(new ActionApiApplicationsInitApplications(true, true, false));
    this.appState$ = this.store.pipe(select(selectApiApplications));
    const currentOrg$ = this.store.pipe(select(selectCurrentOrganisation));
    this.hasUsersPermissions$ = this.store.pipe(select(selectCanAdminUsers));
    this.hasAppsPermissions$ = this.store.pipe(select(selectCanAdminApps));
    this.hasIssuersPermissions$ = this.store.pipe(select(selectCanAdminIssuers));
    this.templateCatalogueEntries$ = this.getTemplateCatalogueEntries();
    const combinedUserPermissionsConnectorsAndFeatures$ = this.hasUsersPermissions$.pipe(
      concatMap((hasUsersPermissionsResp: OrgQualifiedPermission) => {
        this.orgId = hasUsersPermissionsResp?.orgId;
        let connectors$: Observable<Array<Connector>> = of(undefined);
        if (!!hasUsersPermissionsResp?.hasPermission) {
          connectors$ = getConnectors(this.connectorsService, this.orgId);
        }
        let features$: Observable<Map<string, boolean>> = of(undefined);
        if (!!hasUsersPermissionsResp?.hasPermission) {
          features$ = getOrgFeatureFlagMap$(this.organisationsService, this.orgId);
        }
        return combineLatest([of(hasUsersPermissionsResp), connectors$, features$]);
      }),
      map(([hasUsersPermissionsResp, connectorsResp, featuresResp]: [OrgQualifiedPermission, Array<Connector>, Map<string, boolean>]) => {
        const combinedUserPermissionsConnectorsAndFeatures: CombinedPermissionsConnectorsAndFeatures = {
          permission: hasUsersPermissionsResp,
          connectors: connectorsResp,
          features: featuresResp,
        };
        return combinedUserPermissionsConnectorsAndFeatures;
      })
    );

    combineLatest([
      combinedUserPermissionsConnectorsAndFeatures$,
      this.appState$,
      currentOrg$,
      this.templateCatalogueEntries$,
      this.hasAppsPermissions$,
      this.hasIssuersPermissions$,
    ])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        ([
          combinedUserPermissionsConnectorsAndFeaturesResp,
          appStateResp,
          currentOrgResp,
          catalogueResp,
          hasAppsPermissionsResp,
          hasIssuersPermissionsResp,
        ]: [
          CombinedPermissionsConnectorsAndFeatures,
          ApiApplicationsState,
          Organisation,
          Array<CatalogueEntry>,
          OrgQualifiedPermission,
          OrgQualifiedPermission
        ]) => {
          this.orgFeatureKeyToEnabledMap = !!combinedUserPermissionsConnectorsAndFeaturesResp.features
            ? combinedUserPermissionsConnectorsAndFeaturesResp.features
            : new Map();
          this.appState = appStateResp;
          this.currentOrg = currentOrgResp;
          this.templateCatalogueEntries = catalogueResp;
          this.hasAppsPermissions = hasAppsPermissionsResp.hasPermission;
          this.hasUsersPermissions = combinedUserPermissionsConnectorsAndFeaturesResp.permission.hasPermission;
          this.hasIssuersPermissions = hasIssuersPermissionsResp.hasPermission;
          this.currentApplicationModel = cloneDeep(appStateResp.application_model);
          if (this.currentApplicationModel.authentication === undefined) {
            this.currentApplicationModel.authentication = getDefaultApplicationModel().authentication;
          }
          this.applications = appStateResp.applications;
          this.connectors = combinedUserPermissionsConnectorsAndFeaturesResp.connectors;
          this.setAppNameToAppIdMap(appStateResp.applications);
          this.setAppStepperState();
          if (!appStateResp.application_model_status.saving) {
            this.initializeFormGroups();
          }
          if (!this.appStepperState.selectedFqdnAlias) {
            // If unset, set to auto by default
            this.setApplicationModelHosting(FqdnAliasOption.auto);
          }
          this.fqdnAliasOptionData = getFqdnAliasOptionData(getPrimaryExternalName(this.currentApplicationModel.name, this.currentOrg));
          delayStepperAdvanceOnSuccessfulApply(this.stepper, appStateResp.application_model_status);
        }
      );
  }

  private getTemplateCatalogueEntries(): Observable<Array<CatalogueEntry>> {
    const listAllCatalogueEntriesRequestParams: ListAllCatalogueEntriesRequestParams = {
      catalogue_category: 'application_templates',
    };
    return this.cataloguesService.listAllCatalogueEntries(listAllCatalogueEntriesRequestParams).pipe(
      map((catalogueResp) => {
        return catalogueResp.catalogue_entries.filter((catalogueEntry) => catalogueEntry.name !== 'empty');
      }),
      catchError((_) => {
        return of([]);
      })
    );
  }

  public onStepperSelectionChange(selectedIndex: number): void {
    handleStateOnFirstStepSelection(
      selectedIndex,
      this.appState.application_model_status,
      this.appStepperState,
      this.resetModel.bind(this),
      this.initializeStartOptionFormGroup.bind(this)
    );
  }

  private initializeStartOptionFormGroup(): void {
    this.startOptionFormGroup = this.formBuilder.group({
      selectedStartOption: [this.appStepperState.selectedStartOption, Validators.required],
    });
  }

  public ngOnDestroy(): void {
    this.changeDetector.detach();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private setAppNameToAppIdMap(applications: Array<Application>): void {
    this.appNameToAppIdMap.clear();
    for (const app of applications) {
      this.appNameToAppIdMap.set(app.name, app.id);
    }
  }

  private setAppStepperState(): void {
    this.appStepperState.selectedFqdnAlias = getFqdnAliasOptionValue(this.currentApplicationModel?.hosting?.fqdnAliases);
    this.appStepperState.selectedAccess = getAccessOptionValue(this.currentApplicationModel?.hosting);
    this.appStepperState.selectedRuntime = getRuntimeOptionValue(this.currentApplicationModel?.hosting);
    this.appStepperState.selectedAuthFlow = getAuthenticationOptionValue(this.currentApplicationModel?.authentication);
    this.appStepperState.selectedAuthMethod = getAuthenticationMethodOptionValue(this.currentApplicationModel?.authentication);
    this.appStepperState.selectedUserAuth = getAuthorizationOptionValue(this.currentApplicationModel?.authorization);
  }

  private initializeFormGroups(): void {
    this.initializeTemplateSelectFormGroup();
    this.initializeAppFormGroup();
    this.initializeFqdnAliasOptionFormGroup();
    this.initializeAccessFormGroup();
    this.initializeAuthFlowFormGroup();
    this.initializeUpstreamServiceFormGroup();
    this.initializeRuntimeFormGroup();
    this.initializeConnectorFormGroup();
    this.initializeRewriteMediaTypesFormGroup();
    this.initializeCorsTypeFormGroup();
    this.initializeHttp2FormGroup();
    this.initializeWebApplicationFirewallFormGroup();
    this.initializeCommonPathPrefixFormGroup();
    this.changeDetector.detectChanges();
  }

  private initializeTemplateSelectFormGroup(): void {
    const selectedTemplatesValidators = [];
    if (this.appStepperState.selectedStartOption === StartOption.template) {
      selectedTemplatesValidators.push(Validators.required);
    }
    this.templateSelectFormGroup = this.formBuilder.group({
      selectedTemplates: [this.appStepperState.selectedTemplates.map((template) => template.name), selectedTemplatesValidators],
    });
  }

  private initializeAppFormGroup(): void {
    const nameValidators = [Validators.required, this.customValidatorsService.fqdnSingleLabelNameValidator(), Validators.maxLength(100)];
    if (this.currentApplicationModel.app_id !== this.appNameToAppIdMap.get(this.currentApplicationModel.name)) {
      nameValidators.push(this.customValidatorsService.uniqueAppNameValidator(this.applications, this.currentApplicationModel.app_id));
    }
    this.appDetailsFormGroup = this.formBuilder.group({
      name: [getEmptyStringIfUnset(this.currentApplicationModel.name), nameValidators],
      description: [getEmptyStringIfUnset(this.currentApplicationModel.description), [Validators.required]],
    });
  }

  private initializeFqdnAliasOptionFormGroup(): void {
    const fqdnAliasValidators = [this.customValidatorsService.hostnameValidator(), Validators.maxLength(100)];
    this.fqdnAliasOptionFormGroup = this.formBuilder.group({
      selectedFqdnAlias: [false, Validators.required],
      fqdnAliases: ['', fqdnAliasValidators],
    });
  }

  private initializeAccessFormGroup(): void {
    this.accessFormGroup = this.formBuilder.group({
      selectedAccess: [this.appStepperState.selectedAccess, Validators.required],
    });
  }

  private initializeAuthFlowFormGroup(): void {
    const authMethodValidators = [];
    if (this.appStepperState.selectedAuthFlow === AuthFlowOption.own) {
      authMethodValidators.push(Validators.required);
    }
    const userAuthValidators = [];
    if (!!this.appStepperState.selectedAuthFlow && this.appStepperState.selectedAuthFlow !== AuthFlowOption.none) {
      userAuthValidators.push(Validators.required);
    }
    const samlValidators = [];
    if (!!this.appStepperState.selectedAuthMethod && this.appStepperState.selectedAuthMethod === AuthMethodOption.saml) {
      samlValidators.push(Validators.required);
    }
    const redirectUriValidators = [];
    if (!!this.appStepperState.selectedAuthMethod && this.appStepperState.selectedAuthMethod === AuthMethodOption.oidc) {
      redirectUriValidators.push(Validators.required);
    }
    const corsTypeValidators = [];
    if (this.appStepperState.selectedAuthFlow !== AuthFlowOption.none) {
      corsTypeValidators.push(Validators.required);
    }
    this.authFlowFormGroup = this.formBuilder.group({
      selectedAuthFlow: [this.appStepperState.selectedAuthFlow, Validators.required],
      selectedAuthMethod: [this.appStepperState.selectedAuthMethod, authMethodValidators],
      saml_metadata_file: [getEmptyStringIfUnset(this.currentApplicationModel?.authentication?.saml?.saml_metadata_file), samlValidators],
      redirect_uri: [getEmptyStringIfUnset(this.currentApplicationModel?.authentication?.oidc?.redirect_uri), redirectUriValidators],
      logout_url: getEmptyStringIfUnset(this.currentApplicationModel?.authentication?.proxy?.logout_url),
      redirect_after_signin_path: getEmptyStringIfUnset(this.currentApplicationModel?.authentication?.proxy?.redirect_after_signin_path),
      selectedUserAuth: [this.appStepperState.selectedUserAuth, userAuthValidators],
    });
  }

  private initializeUpstreamServiceFormGroup(): void {
    this.upstreamServiceFormGroup = this.formBuilder.group({
      upstreamService: this.formBuilder.array([]),
    });
    this.setDefaultData();
  }

  public setDefaultData() {
    this.addUpstreamService();
  }

  public addUpstreamService() {
    const hostnameValidators = [this.customValidatorsService.hostnameOrIP4Validator(), Validators.maxLength(100)];
    const portValidators = [this.customValidatorsService.portValidator(), Validators.max(65535)];
    const ipValidators = [this.customValidatorsService.ip4AddressValidator()];
    if (isAccessedOnPrem(this.currentApplicationModel.hosting)) {
      hostnameValidators.push(Validators.required);
      portValidators.push(Validators.required);
    }
    if (isAccessedViaVpn(this.currentApplicationModel.hosting)) {
      ipValidators.push(Validators.required);
    }

    let upstreamServices = this.upstreamServiceFormGroup.get('upstreamService') as UntypedFormArray;
    if (this.currentApplicationModel?.hosting?.on_prem?.upstream_services?.length > 0) {
      this.currentApplicationModel?.hosting?.on_prem?.upstream_services.forEach((service) => {
        let tls_type = TlsType.NoTls;
        if (service.tls_enabled && service.tls_verify) {
          tls_type = TlsType.TlsAndVerify;
        }
        if (service.tls_enabled && !service.tls_verify) {
          tls_type = TlsType.Tls;
        }
        upstreamServices.push(
          this.formBuilder.group({
            hostname: [getEmptyStringIfUnset(service?.hostname), hostnameValidators],
            port: [getEmptyStringIfUnset(service?.port), portValidators],
            ip_address: [getEmptyStringIfUnset(service?.ip_address), ipValidators],
            tls_enabled: service?.tls_enabled,
            tls_verify: service?.tls_verify,
            expose_type: service?.expose_type ? service.expose_type : 'application',
            hostname_alias: getEmptyStringIfUnset(service?.hostname_alias),
            tls: tls_type,
          })
        );
      });
    } else {
      upstreamServices.push(
        this.formBuilder.group({
          hostname: ['', hostnameValidators],
          port: ['', portValidators],
          ip_address: ['', ipValidators],
          tls_enabled: false,
          tls_verify: false,
          expose_type: 'application',
          hostname_alias: '',
          tls: 'no_tls',
        })
      );
    }
  }

  private initializeRuntimeFormGroup(): void {
    const runtimeValidators = [];
    const imageValidators = [];
    const versionTagValidators = [];
    if (canConfigureRuntime(this.currentApplicationModel)) {
      runtimeValidators.push(Validators.required);
      imageValidators.push(Validators.required);
    }
    const portValidators = [this.customValidatorsService.portValidator(), Validators.max(65535)];
    if (canConfigureRuntime(this.currentApplicationModel) && this.appStepperState.selectedRuntime === RuntimeOption.own) {
      portValidators.push(Validators.required);
      versionTagValidators.push(Validators.required);
    }
    this.runtimeFormGroup = this.formBuilder.group({
      selectedRuntime: [this.appStepperState.selectedRuntime, runtimeValidators],
      image: [getEmptyStringIfUnset(getImageValueFromModel(this.currentApplicationModel)), imageValidators],
      port: [getEmptyStringIfUnset(this.currentApplicationModel?.hosting?.in_cloud?.runtime?.own?.port), portValidators],
      version_tag: [
        getEmptyStringIfUnset(this.currentApplicationModel?.hosting?.in_cloud?.runtime?.own?.version_tag),
        versionTagValidators,
      ],
    });
  }

  private initializeConnectorFormGroup(): void {
    const connectorNameValidators = [];
    if (isAccessedOnPrem(this.currentApplicationModel.hosting)) {
      connectorNameValidators.push(Validators.required);
    }
    this.connectorFormGroup = this.formBuilder.group({
      connector_name: [getEmptyStringIfUnset(this.currentApplicationModel?.hosting?.on_prem?.connector_name), connectorNameValidators],
    });
  }

  private initializeRewriteMediaTypesFormGroup(): void {
    this.rewriteMediaTypesFormGroup = this.formBuilder.group({
      rewrite_common_media_types: this.currentApplicationModel?.hosting?.on_prem?.rewrite_common_media_types,
    });
  }

  private initializeCorsTypeFormGroup(): void {
    const corsTypeValidators = [];
    if (!!this.currentApplicationModel?.hosting?.on_prem?.cors?.enabled) {
      corsTypeValidators.push(Validators.required);
    }
    this.corsTypeFormGroup = this.formBuilder.group({
      hasCors: this.currentApplicationModel?.hosting?.on_prem?.cors?.enabled,
      corsType: [this.currentApplicationModel?.hosting?.on_prem?.cors?.corsType, corsTypeValidators],
    });
  }

  private initializeHttp2FormGroup(): void {
    let disable_http2 = false;
    if (this.currentApplicationModel?.hosting?.on_prem?.upstream_services?.length > 0) {
      disable_http2 = this.currentApplicationModel.hosting.on_prem.upstream_services[0]?.protocol_config?.http_config?.disable_http2
        ? this.currentApplicationModel.hosting.on_prem.upstream_services[0].protocol_config.http_config.disable_http2
        : false;
    }
    this.http2FormGroup = this.formBuilder.group({
      disable_http2: disable_http2,
    });
  }

  private initializeWebApplicationFirewallFormGroup(): void {
    let proxy_location = false;
    if (
      this.currentApplicationModel?.hosting?.on_prem?.agent?.proxy_location &&
      this.currentApplicationModel.hosting.on_prem.agent.proxy_location === Environment.ProxyLocationEnum.in_cloud
    ) {
      proxy_location = true;
    }
    this.webApplicationFirewallFormGroup = this.formBuilder.group({
      proxy_location: proxy_location,
    });
  }

  private initializeCommonPathPrefixFormGroup(): void {
    this.commonPathPrefixFormGroup = this.formBuilder.group({
      common_path_prefix: this.currentApplicationModel?.authorization?.application_model_routing?.common_path_prefix,
    });
  }

  public showAccessOptions(): boolean {
    return !!this.appStepperState.selectedAccess && this.appStepperState.selectedAccess !== AccessOption.internet;
  }

  public showAuthFlowSubStepper(): boolean {
    return !!this.appStepperState.selectedAuthFlow && this.appStepperState.selectedAuthFlow !== AuthFlowOption.none;
  }

  public onAccessOptionChange(accessOption: AccessOption): void {
    // Workaround for angular stepper bug:
    // See: https://github.com/angular/components/issues/20923
    this.accessOptionChanged = true;
    // We need to clear the connector so that the user cannot submit an incorrect
    // connector type if they change the selected access option.
    this.clearConnectorSelection();
    if (accessOption === AccessOption.internet) {
      this.appStepperController.handleInternetHostingOptionSelection(this.currentApplicationModel);
    }
    if (accessOption === AccessOption.agent) {
      this.appStepperController.handleAgentHostingOptionSelection(this.currentApplicationModel);
    }
    if (accessOption === AccessOption.vpn) {
      this.appStepperController.handleVpnHostingOptionSelection(this.currentApplicationModel);
    }
    if (accessOption === AccessOption.cloud) {
      this.appStepperController.handleCloudHostingOptionSelection(this.currentApplicationModel);
    }
    this.updateApplicationModel();
  }

  private clearConnectorSelection(): void {
    this.currentApplicationModel.hosting.on_prem.connector_name = '';
  }

  public onAuthFlowChange(authFlowOption: AuthFlowOption): void {
    // Workaround for angular stepper bug:
    // See: https://github.com/angular/components/issues/20923
    this.authFlowOptionChanged = true;
    if (authFlowOption === AuthFlowOption.none) {
      this.appStepperController.handleNoAuthOptionSelection(this.currentApplicationModel);
    }
    if (authFlowOption === AuthFlowOption.own) {
      this.appStepperController.handleOwnAuthOptionSelection(this.currentApplicationModel);
    }
    if (authFlowOption === AuthFlowOption.proxy) {
      this.appStepperController.handleProxyAuthOptionSelection(this.currentApplicationModel);
    }
    this.updateApplicationModel();
  }

  public updateApplicationModel(): void {
    this.store.dispatch(new ActionApiApplicationsUpdateApplicationModel(this.currentApplicationModel));
  }

  public resetHostMethodOptionChanged(): void {
    this.accessOptionChanged = false;
  }

  public resetAuthFlowOptionChanged(): void {
    this.authFlowOptionChanged = false;
  }

  public onFormBlur<T extends object>(form: UntypedFormGroup, formField: string, obj: T): void {
    modifyDataOnFormBlur(form, formField, this.modifyAppStepperDataOnFormBlur.bind(this), obj);
  }

  private modifyAppStepperDataOnFormBlur<T extends object>(form: UntypedFormGroup, formField: string, obj: T): void {
    obj[formField] = form.get(formField).value;
    if (formField === 'name') {
      obj[formField] = form.get(formField).value.toLowerCase().trim();
    }
    this.updateApplicationModel();
  }

  public onAppNameChange(): void {
    if (this.appDetailsFormGroup.get('name').value !== this.currentApplicationModel.name) {
      this.currentApplicationModel.app_id = undefined;
    }
    this.appDetailsFormGroup.patchValue({ name: this.appDetailsFormGroup.get('name').value.toLowerCase() });

    this.modifyAppStepperDataOnFormBlur(this.appDetailsFormGroup, 'name', this.currentApplicationModel);
  }

  public disableAccessOption(option: AccessOption): boolean {
    if (option === AccessOption.vpn && !this.hasAtLeastOneVpnConnector()) {
      return true;
    }
    if (option !== AccessOption.internet && option !== AccessOption.agent && option !== AccessOption.vpn) {
      return true;
    }
    return false;
  }

  public disableAuthFlowOption(option: AuthFlowOption): boolean {
    return option === AuthFlowOption.proxy && disableProxyAuthFlowOption(this.currentApplicationModel.hosting);
  }

  public onTemplateSelectionUpdate(selectedTemplateNames: Array<string>): void {
    const selectedTemplates: Array<CatalogueEntry> = [];
    for (const templateName of selectedTemplateNames) {
      const targetTemplate = this.getTemplateFromName(templateName);
      if (!!targetTemplate) {
        const parsedTargetTemplate = JSON.parse(targetTemplate.content);
        if (parsedTargetTemplate.hosting.on_prem.upstream_service) {
          parsedTargetTemplate.hosting.on_prem.upstream_services = [parsedTargetTemplate.hosting.on_prem.upstream_service];
        }
        targetTemplate.content = JSON.stringify(parsedTargetTemplate);
        selectedTemplates.push(targetTemplate);
      }
      // TODO: need to update 'currentApplicationModel' here with the selected templates.
      // TODO: need a way to resolve conflicts when multiple templates are selected.
      if (selectedTemplates.length === 1) {
        this.currentApplicationModel = JSON.parse(selectedTemplates[0].content);
      }
    }
    this.appStepperState.selectedTemplates = selectedTemplates;
    this.updateApplicationModel();
  }

  public disableTemplateOptions(templateName: string): boolean {
    if (templateName === 'empty') {
      return false;
    }
    const emptyTemplate = this.getEmptyTemplateFromList(this.appStepperState.selectedTemplates);
    if (!!emptyTemplate) {
      return true;
    }
    return false;
  }

  private getTemplateFromName(templateName: string): CatalogueEntry | undefined {
    return this.templateCatalogueEntries.find((template) => template.name === templateName);
  }

  public getEmptyTemplateFromList(selectedTemplates: Array<CatalogueEntry>): CatalogueEntry {
    return selectedTemplates.find((template) => template.name === 'empty');
  }

  public downloadTemplate(): void {
    const fileName = this.currentApplicationModel.name + '_application_template_data';
    const versionedAppModel: VersionedApplicationModel = {
      version: this.currentAppModelVersion,
      model: this.currentApplicationModel,
    };
    downloadJSON(versionedAppModel, this.renderer, fileName);
  }

  public onReadTemplateFile(event: any): void {
    const file = getFile(event);
    if (!file) {
      return;
    }
    if (!uploadIsJSON(file)) {
      this.notificationService.error(
        'This file does not appear to be in JSON format. Please upload a JSON file.' +
          ' File is of type "' +
          file.type +
          '". Expected type "application/json".'
      );
      return;
    }
    // Need to reassign the progressBarController in order to
    // trigger the update in the template.
    this.templateProgressBarController = this.templateProgressBarController.initializeProgressBar();
    this.isUploadingTemplate = true;
    const reader = new FileReader();
    reader.onload = () => {
      const newTemplate: VersionedApplicationModel = getJSONFromFileReader(reader);
      if (!!newTemplate) {
        this.onSuccessfulTemplateParse(newTemplate, file.name);
        return;
      }
      this.onFailedTemplateParse();
    };
    reader.readAsText(file);
  }

  private translateApplicationModelVersion(newTemplate: VersionedApplicationModel | ApplicationModel): VersionedApplicationModel {
    let translatedTemplate = getVersionedApplicationModel(newTemplate);
    if (!translatedTemplate || (!translatedTemplate.model && !translatedTemplate.version)) {
      translatedTemplate = {
        version: this.currentAppModelVersion,
        model: getUnversionedApplicationModel(newTemplate),
      };
    }
    if (!translatedTemplate.version || translatedTemplate.version !== this.currentAppModelVersion) {
      // Do the translation logic.
      // TODO: add this logic when it becomes applicable.
    }
    return translatedTemplate;
  }

  private onSuccessfulTemplateParse(newTemplate: VersionedApplicationModel, fileName: string): void {
    const translatedTemplate = this.translateApplicationModelVersion(newTemplate);
    const fileTemplateEntry: CatalogueEntry = { name: fileName, content: JSON.stringify(translatedTemplate) };
    this.templateCatalogueEntries.push(fileTemplateEntry);
    this.appStepperState.selectedTemplates = [fileTemplateEntry];
    this.currentApplicationModel = translatedTemplate.model;
    this.updateApplicationModel();
    // Need to reassign the progressBarController in order to
    // trigger the update in the template.
    this.templateProgressBarController = this.templateProgressBarController.updateProgressBarValue(1, 1);
    this.changeDetector.detectChanges();
    this.delayHideTemplateProgressBar();
    this.isUploadingTemplate = false;
  }

  private onFailedTemplateParse(): void {
    this.templateProgressBarController = this.templateProgressBarController.onFailedUpload();
    this.isUploadingTemplate = false;
    this.notificationService.error('Failed to parse the JSON file. Please check that the format is correct and try again.');
    this.changeDetector.detectChanges();
  }

  /**
   * Delay hiding the progress bar by 2 seconds to match the successful
   * upload notification
   */
  public delayHideTemplateProgressBar(): void {
    setTimeout(() => {
      // Need to re-assign the progressBarController in order to
      // trigger the update in the template.
      this.templateProgressBarController = this.templateProgressBarController.resetProgressBar();
      this.changeDetector.detectChanges();
    }, this.templateProgressBarController.hideProgressBarDelay);
  }

  public isStartStepValid(): boolean {
    if (this.startOptionFormGroup.invalid) {
      return false;
    }
    if (this.templateSelectFormGroup.invalid) {
      return false;
    }
    return true;
  }

  public isAccessStepValid(): boolean {
    if (
      this.accessFormGroup.invalid ||
      this.upstreamServiceFormGroup.invalid ||
      this.runtimeFormGroup.invalid ||
      this.connectorFormGroup.invalid
    ) {
      return false;
    }
    return true;
  }

  public hasAllPermissions(): boolean {
    return !!this.hasAppsPermissions && !!this.hasUsersPermissions && !!this.hasIssuersPermissions;
  }

  public showNoPermissionsText(): boolean {
    if (this.hasAppsPermissions === undefined || this.hasUsersPermissions === undefined || this.hasIssuersPermissions === undefined) {
      return false;
    }
    if (this.hasAllPermissions()) {
      return false;
    }
    return true;
  }

  public getAppUrlForLink(): string {
    return getApplicationUrl(this.currentApplicationModel?.name, this.currentOrg?.subdomain);
  }

  // TODO: need to add this info for the final step in the stepper.
  public getInstructionsForUser(): string {
    return '';
  }

  public isAppStepperComplete(): boolean {
    return this.appState.application_model_status.complete;
  }

  public submitApplicationModel(appModel: ApplicationModel): void {
    if (this.appStepperState.selectedAccess === AccessOption.agent && !appModel.hosting.on_prem.connector_name) {
      this.notificationService.error(`Please return to the 'Access' step and select a connector.`);
      return;
    }
    this.pollForAppStatus().subscribe();
    this.store.dispatch(new ActionApiApplicationsSubmittingApplicationModel(appModel, undefined, this.arePolicyRulesEnabled()));
  }

  public showTemplateSelectStep(): boolean {
    return this.appStepperState.selectedStartOption === StartOption.template;
  }

  private resetModel(): void {
    this.store.dispatch(new ActionApiApplicationsResetApplicationModel());
  }

  public isInProgress(): boolean {
    return (
      (!!this.currentApplicationModel.name || !!this.currentApplicationModel.description) &&
      !this.appState.application_model_status.complete
    );
  }

  public setApplicationModelHosting(fqdnAliasOption: FqdnAliasOption): void {
    this.appStepperState.selectedFqdnAlias = fqdnAliasOption;
    if (fqdnAliasOption === FqdnAliasOption.auto) {
      this.appStepperController.handleAutoFqdnAliasOptionSelection(this.currentApplicationModel);
    }
    if (fqdnAliasOption === FqdnAliasOption.manual) {
      this.appStepperController.handleManualFqdnAliasOptionSelection(this.currentApplicationModel);
    }
    this.updateApplicationModel();
  }

  public getFqdnAliasesAsString(): string {
    return this.currentApplicationModel.hosting.fqdnAliases.fqdnAliasList.join(', ');
  }

  public getAuthClientsLink(): string {
    return `https://${window.location.hostname}/application-authentication-clients?${this.currentOrg.id}`;
  }

  private isAppStatusGood(value: [RuntimeStatus, number]): boolean {
    if (!value) {
      return false;
    }
    if (!value[0]?.overall_status) {
      return false;
    }
    if (value[0].overall_status === RuntimeStatus.OverallStatusEnum.good) {
      return true;
    }
    return false;
  }

  private getListRuntimeStatusRequestParams(appId: string): ListRuntimeStatusRequestParams {
    return {
      app_id: !!appId ? appId : getNoAppIdForStatusString(),
      env_name: getDefaultEnvironmentName(),
      org_id: this.currentOrg.id,
    };
  }

  private getRuntimeStatus$(): Observable<RuntimeStatus> {
    return this.applicationsService.listRuntimeStatus(
      this.getListRuntimeStatusRequestParams(this.currentApplicationModel.app_id),
      'body',
      getIgnoreErrorsHeader()
    );
  }

  private updateAndRefreshAppStatus(status: RuntimeStatus): void {
    this.appStatus = status;
    this.changeDetector.detectChanges();
  }

  private retryStatusRetrieval$(count: number): Observable<Array<number | RuntimeStatus> | Array<number>> {
    return this.getRuntimeStatus$().pipe(
      delay(1000),
      mergeMap((resp) => {
        this.updateAndRefreshAppStatus(resp);
        return of([resp, count]);
      }),
      catchError(() => {
        // exponentially increase delay and add a random number of milliseconds to prevent clients from sending requests
        // in synchronized waves.
        return of([undefined, count + 1]).pipe(delay(1000 * (Math.pow(2, count) - 1) + Math.floor(Math.random() * 1000) + 1));
      })
    );
  }

  private pollForAppStatus(): Observable<Array<number | RuntimeStatus> | Array<number>> {
    return combineLatest([this.getRuntimeStatus$(), of(0)]).pipe(
      takeUntil(this.unsubscribe$),
      takeWhile((value) => !this.isAppStatusGood(value), true),
      catchError(() => of([undefined, 0])), // continue to poll if there is an error
      expand(([status, count]: [RuntimeStatus, number]) => {
        if (!!status && !this.currentApplicationModel.app_id) {
          // Stop polling if the app model's app_id is reset.
          return EMPTY;
        }
        if (!status?.overall_status || status.overall_status !== RuntimeStatus.OverallStatusEnum.good) {
          return this.retryStatusRetrieval$(count);
        }
        return EMPTY;
      }),
      reduce((_) => _)
    );
  }

  public hasAtLeastOneVpnConnector(): boolean {
    if (!this.connectors) {
      return false;
    }
    return this.connectors.some((connector) => connector.spec.connector_type === ConnectorSpec.ConnectorTypeEnum.ipsec);
  }

  public isHostedApplicationsEnabled(): boolean {
    const result = this.orgFeatureKeyToEnabledMap.get(OrgFeatures.hosted_applications);
    if (result === undefined) {
      return true;
    }
    return result;
  }

  public arePolicyRulesEnabled(): boolean {
    const result = this.orgFeatureKeyToEnabledMap.get(OrgFeatures.policy_rules);
    if (result === undefined) {
      return true;
    }
    return result;
  }

  public getFilteredAccessOptionData(): Array<AccessOptionData> {
    const filteredOptions: Array<AccessOptionData> = [];
    for (const option of this.accessOptionData) {
      if (option.value !== AccessOption.cloud) {
        filteredOptions.push(option);
        continue;
      }
      if (this.isHostedApplicationsEnabled()) {
        filteredOptions.push(option);
      }
    }
    return filteredOptions;
  }

  public getModel(): ApplicationModel | undefined {
    return this.currentApplicationModel;
  }

  public showApplicationPermissions(): boolean {
    return (
      this.currentApplicationModel.authorization.single_role_users ||
      (this.currentApplicationModel.authorization.user_defined_roles.enabled &&
        this.currentApplicationModel.authorization.user_defined_roles.roles.length !== 0)
    );
  }

  public getRolesListAsString(): string {
    if (
      !!this.currentApplicationModel.authorization?.user_defined_roles?.roles &&
      this.currentApplicationModel.authorization.user_defined_roles.roles.length !== 0
    ) {
      return this.currentApplicationModel.authorization.user_defined_roles.roles.map((role) => role.spec.name).join(', ');
    }
    return '';
  }

  public canDeactivate(): Observable<boolean> | boolean {
    this.resetModel();
    return true;
  }
}
